import React, {useCallback} from 'react';
import clsx from 'clsx';
import {useFormContext} from 'react-hook-form';
import {
    IconTooltip,
    PhoneField,
    StateField,
    AdditionalAddressField,
    AddressField,
    CityField,
    FieldSet,
    FirstNameField,
    LastNameField,
    ZipCodeField,
} from '../atoms';
import {makeStyles} from '@material-ui/core/styles';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {buttonifyFromProps} from '../hocs';
import {AddressFormValues, AddressTypeEnum, TextField} from '../index';
import {CountryStatesHelper} from '../utils';
import {cssText} from '@ohoareau/css-utils';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: 10,
    },
    fieldset: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    form: {
        marginTop: 0,
    },
    inputWrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
    },
    input: {
        marginBottom: theme.spacing(1),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    inputMessage: {
        ...cssText(theme, 'standard', 'caption'),
        marginTop: 4,
    },
    half: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(50% - 16px)',
        },
    },
    third: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(33.33% - 16px)',
        },
    },
    twoThird: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(66.66% - 16px)',
        },
    },
    threeQuarters: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(75% - 16px)',
        },
    },
    inputTooltip: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export function EditAddress({
    className,
    onSelectAddress,
    defaultAddress,
    onCancel,
    locale,
    autoFocus = false,
    addressType,
}: EditAddressProps) {
    const classes = useStyles();
    const {
        handleSubmit,
        register,
        errors,
        control,
        formState: {isSubmitting},
    } = useFormContext<AddressFormValues>();
    const {t} = useLuniiTranslation();
    const formatCountry = useCallback(
        (country: string) => t(`country_${country.substring(3, 5).toLowerCase()}_label`),
        [],
    );
    const handleSearchClick = useCallback(() => {
        handleSubmit((formData) => {
            const {
                address1,
                address2,
                firstName,
                lastName,
                city,
                zipCode,
                state,
                selectCountry,
                phone,
            } = formData;
            onSelectAddress({
                address1,
                address2,
                firstName,
                lastName,
                city,
                state,
                zipCode,
                country: formatCountry(selectCountry),
                locale: selectCountry,
                phone,
            });
        })();
    }, [handleSubmit, onSelectAddress]);
    const {Button: SearchButton, Button2: CancelButton} = buttonifyFromProps(
        {
            buttonLabel: t('form_address_search_address'),
            buttonTarget: handleSearchClick,
            button2Label: t('form_address_cancel_edit'),
            button2Target: onCancel,
        },
        ['primary,size=small_mobile_only', 'plain,size=small_mobile_only'],
    );
    const x = {errors, register};
    const defaultCountry = (defaultAddress || {}).country || formatCountry(locale);
    return (
        <div className={className}>
            <form>
                <FieldSet className={clsx(classes.fieldset, classes.form)}>
                    <FirstNameField
                        disabled={isSubmitting}
                        className={clsx(classes.input, classes.half)}
                        autoFocus={autoFocus}
                        required
                        min={1}
                        max={100}
                        {...x}
                    />
                    <LastNameField
                        disabled={isSubmitting}
                        className={clsx(classes.input, classes.half)}
                        required
                        min={1}
                        max={100}
                        {...x}
                    />
                    <AddressField
                        disabled={isSubmitting}
                        className={classes.input}
                        required
                        min={1}
                        {...x}
                    />
                    <AdditionalAddressField
                        disabled={isSubmitting}
                        className={clsx(classes.input, classes.threeQuarters)}
                        min={1}
                        {...x}
                    />
                    <ZipCodeField
                        disabled={isSubmitting}
                        className={clsx(classes.input, classes.third)}
                        required
                        min={1}
                        {...x}
                    />
                    <CityField
                        disabled={isSubmitting}
                        className={clsx(classes.input, classes.twoThird)}
                        required
                        min={1}
                        {...x}
                    />
                    {CountryStatesHelper.countryFromLocaleHasStates(locale) && (
                        <StateField
                            options={CountryStatesHelper.statesFromLocale(locale)}
                            disabled={isSubmitting}
                            className={classes.input}
                            control={control}
                            required
                            min={1}
                            {...x}
                        />
                    )}
                    <div className={classes.inputWrapper}>
                        <TextField
                            disabled
                            className={clsx(classes.input, classes.half)}
                            name={''}
                            label={'fields_country_label'}
                            value={defaultCountry}
                        />
                        <IconTooltip
                            className={classes.inputTooltip}
                            content={t('form_address_uneditable_country_tooltip')}
                            iconType={'question'}
                        />
                    </div>
                    {addressType === AddressTypeEnum.Delivery && (
                        <>
                            <PhoneField
                                className={clsx(classes.input)}
                                defaultCountry={locale?.substring(3, 5)?.toUpperCase()}
                                control={control}
                                message={
                                    <div className={classes.inputMessage}>
                                        {t('form_address_delivery_address_phone_explanation')}
                                    </div>
                                }
                                required
                                {...x}
                            />
                        </>
                    )}
                    <input
                        type={'hidden'}
                        id={'selectCountry'}
                        name={'selectCountry'}
                        required
                        value={locale}
                        ref={register}
                    />
                </FieldSet>
            </form>
            <div>
                {SearchButton && <SearchButton className={classes.button} />}
                {defaultAddress && (addressType === AddressTypeEnum.Billing || defaultAddress.phone) && CancelButton && <CancelButton />}
            </div>
        </div>
    );
}

export interface EditAddressProps {
    className?: any;
    defaultAddress?: any;
    onSelectAddress?: any;
    onCancel?: any;
    locale: string;
    autoFocus?: boolean;
    addressType?: AddressTypeEnum | undefined;
}

export default EditAddress;
