export const errors: any = {
    login: {
        lunii: {
            '7.1': 'errors_login_bad_credentials',
        },
    },
    register: {
        lunii: {
            '4.0': 'errors_register_error_email',
        }
    },
    updatePassword: {
        lunii: {
            '9.1': 'errors_passwordupdate_user_not_validated',
            '9.3': 'errors_passwordupdate_password_does_not_match',
            '9.4': 'errors_passwordupdate_new_equals_to_old_password',
        }
    },
    forgotPassword: {
        lunii: {
            '8.5': 'errors_forgot_password_error_email',
        },
    },
    resendValidationEmail: {
        lunii: {
            '10.0': 'error_resend_validation',
            '10.1': 'error_resend_validation',
            '10.2': 'error_resend_validation',
            '10.3': 'error_resend_validation',
        }
    },
    validateCart: {
        lunii: {
            '67.17': 'error_validating_cart_discount_already_used',
            '67.3': 'error_validating_cart_error_on_validate',
            '67.4': 'error_validating_cart_error_on_validate',
        }
    },
    saveCart: {
        lunii: {
            '59.1': 'error_updating_cart_already_validated',
        }
    },
    createPurchasePayment: {
        lunii: {
            '5.10': 'error_creating_payment_intent',
            '5.11': 'error_creating_payment_paypal',
            '5.12': 'error_creating_payment_paypal',
        }
    },
    activateCartDiscountCode: {
        lunii: {
            '65.6': 'error_cart_discounts_common_error',
            '65.9': 'error_cart_discounts_common_error',
            '65.10': 'error_cart_discounts_common_error',
            '65.11': 'error_cart_discounts_code_already_used',
            '65.12': 'error_cart_discounts_code_already_used',
            '65.14': 'error_cart_discounts_common_error',
            '65.15': 'error_cart_discounts_code_over_usage_count',
            '65.16': 'error_cart_discounts_products_not_on_cart',
            '65.17': 'error_cart_discounts_common_error',
            '65.21': 'error_cart_discounts_common_error_gift_card',
            '65.22': 'error_cart_discounts_mixed_cart',
        }
    },
    codeAlbum: {
        lunii: {
            '31.0': 'error_validate_codealbum_unable_to_retrieve_code',
            '31.1': 'error_validate_codealbum_max_usage_count_reached',
            '31.2': 'error_validate_codealbum_unable_to_retrieve_offer',
            '31.3': 'error_validate_codealbum_code_not_active',
            '31.4': 'error_validate_codealbum_code_not_yet_active',
            '31.5': 'error_validate_codealbum_code_expired',
            '31.6': 'error_validate_codealbum_no_available_products',
            '31.9': 'error_validate_codealbum_code_already_used',
            '32.0': 'error_activate_codealbum_unable_to_retrieve_code',
            '32.1': 'error_activate_codealbum_max_usage_count_reached',
            '32.2': 'error_activate_codealbum_unable_to_retrieve_offer',
            '32.3': 'error_activate_codealbum_code_not_active',
            '32.4': 'error_activate_codealbum_code_not_yet_active',
            '32.5': 'error_activate_codealbum_code_expired',
            '32.6': 'error_activate_codealbum_no_available_products',
            '32.19': 'error_activate_codealbum_code_already_used',
            '32.21': 'error_activate_codealbum_unable_to_retrieve_user_delivery_infos',
            '32.22': 'error_activate_codealbum_unable_to_retrieve_user_billing_infos',
            '32.23': 'error_activate_codealbum_unable_to_retrieve_user',
        }
    },
    stripe: {
        lunii: {
            'card_declined': 'error_payment_stripe_declined_card',
            'card_number_in_name_field': 'error_payment_stripe_card_number_in_name_field',
            'incorrect_cvc': 'error_payment_stripe_incorrect_cvc',
            'expired_card': 'error_payment_stripe_expired_card',
            'processing_error': 'error_payment_stripe_processing_error',
        }
    },
    giftCard: {
        lunii: {
            '24.0': 'gift_card_balance_error_unable_to_retrieve_account_balance',
            '24.1': 'gift_card_balance_error_unable_to_retrieve_account_balance',
            '22.4': 'gift_card_balance_error_gift_card_is_invalid',
            '22.5': 'gift_card_balance_error_gift_card_is_invalid',
            '22.6': 'gift_card_balance_error_gift_card_is_expired',
            '26.2': 'gift_card_merge_error_unable_to_merge',
            '26.3': 'gift_card_merge_error_unable_to_merge',
        },
        http: {
            'default': 'gift_card_balance_error_http_default'
        },
    },
    subscriptionCoupon: {
        lunii: {
            '1.0': 'my_subscription_activate_form_error_default',
            '2.0': 'my_subscription_activate_form_error_code_not_valid',
            '2.1': 'my_subscription_activate_form_error_code_already_activated',
            '2.2': 'my_subscription_activate_form_error_code_expired',
        },
    },
    creditCard: {
        lunii: {
            '14.0': 'stripe_cards_get_unable_to_retrieve_cards_for_user',
            '15.3': 'stripe_card_post_error_card_already_exists',
            '15.5': 'stripe_card_post_error_creating_card',
        },
        http: {
            'default': 'stripe_card_error_http_default'
        },
    },
    subscription: {
        lunii: {
            '1.0': 'error_subscription_server',
            '17.2': 'error_subscription_not_enough_credits',
            '17.22': 'error_subscription_no_billing_infos',
        },
    },
    fah: {
        lunii: {
            '37.0': 'error_fah_update_retrieve_fah',
            '37.1': 'error_fah_update_name',
            '37.2': 'error_fah_update_serial',
            '37.3': 'error_fah_update_serial',
        }
    },
}

export default errors;
