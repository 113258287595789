export const routes = [
    {path: '/profile', screen: 'profile', secured: true},
    {path: '/products', screen: 'products', secured: true},
    {path: '/billing', screen: 'billing', secured: true},
    {path: '/gift-cards', screen: 'giftCards', secured: true},
    {path: '/promo-codes', screen: 'promoCodes', secured: true},
    {path: '/orders', screen: 'orders', secured: true},
    {path: '/list', screen: 'list', secured: true},
    {path: '/forgot-password', screen: 'forgotPassword'},
    {path: '/login', screen: 'login'},
    {path: '/library', redirect: 'audiobooks'},
    {path: '/library/studio', redirect: 'audiobooks'},
    {path: '/wishlist', screen: 'wishlist', secured: true},
    {path: '/', screen: 'home', secured: true},
    {path: '/audiobooks', screen: 'audiobooks', secured: true}
];

export default routes;
