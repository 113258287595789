import React, {useState, useCallback, useMemo, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useLocation} from '@reach/router';
import {
    useLuniiStorage,
    useLuniiTranslation,
    LoginForm,
    ForgotPasswordForm,
    RegisterForm,
    useLuniiUserTokens,
    Button,
    InfosPanelForm,
    useLuniiSpa,
    useLuniiUser,
    useLuniiPasswordUpdateModal,
} from '../../../ui';
import {ActionFullScreenLayout} from '../../layouts/ActionFullScreenLayout';
import {storageKeys} from '../../../../configs/site';
import {usePageTracking} from '../../../hooks/usePageTracking';

const useStyles = makeStyles((theme) => ({
    box: {
        margin: 0,
    },
}));

// todo later possible refactor for panels
// TODO handle url params for mode setup on page landing
export function LoginScreen() {
    const classes = useStyles();
    const {setCurrentUserTokens} = useLuniiUserTokens();
    const [mode, setMode] = useState('login');
    const {t} = useLuniiTranslation();
    const {open: openPasswordUpdateModal} = useLuniiPasswordUpdateModal();
    const {user} = useLuniiUser();
    const storage = useLuniiStorage();
    const {navigate} = useLuniiSpa();
    const location = useLocation();
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        if (user) {
            let params: string | undefined;
            if (location?.search && undefined !== window) {
                const search = new URLSearchParams(location.search);
                const transferedLocationParams = search.get('transferedLocationParams');
                const decodedTransferedLocationParams = transferedLocationParams
                    ? decodeURIComponent(transferedLocationParams)
                    : '';
                params = decodedTransferedLocationParams || location.search;
            }
            navigate(`/${params || ''}`);
        }
        if (urlParams.get('mode') === 'register') setMode('register');
    }, [user, location?.search, navigate]);
    const loadedLocaleStorage = useMemo(() => storage?.getItem(storageKeys.locale), []);
    const handleForgotPasswordClick = useCallback(() => setMode('forgot-password'), []);
    const handleRegisterClick = useCallback(() => {
        navigate('/login?mode=register'); // todo: location && navigation using hooks
        setMode('register');
    }, []);
    const handleCancelClick = useCallback(() => {
        navigate('/login'); // todo: location && navigation using hooks
        setMode('login');
    }, []);
    const handleForgotPasswordSuccess = useCallback(() => setMode('forgot-password-success'), []);
    const handleRegisterSuccess = useCallback(
        async (language: string) => {
            // change locale from storage locale.
            const locale = storage?.getItem(storageKeys.locale);
            if (language !== locale) storage?.setItem(storageKeys.locale, language);
            setMode('register-success');
        },
        [storage],
    );
    const handleRegisterSuccessClick = useCallback(async () => {
        // if locale has been changed, reload the page
        if (loadedLocaleStorage !== storage?.getItem(storageKeys.locale)) {
            document.location.reload();
        } else {
            setMode('login');
        }
    }, [loadedLocaleStorage, storage]);
    const handleAuthTokensChange = useCallback(
        (tokens: any) => {
            setCurrentUserTokens(tokens);
            navigate('/');
        },
        [setCurrentUserTokens],
    );
    const handleMustUpdatePassword = useCallback(
        (loginData: any) => {
            openPasswordUpdateModal(loginData);
        },
        [openPasswordUpdateModal],
    );
    const registerButton = () => (
        <Box
            className={classes.box}
            mb={3}
            onClick={handleRegisterClick}
        >
            <Button
                color="secondary"
                size="small"
            >
                {t('buttons_register_label')}
            </Button>
        </Box>
    );
    const loginButton = () => (
        <Box
            className={classes.box}
            mb={3}
            onClick={handleCancelClick}
        >
            <Button
                color="secondary"
                size="small"
            >
                {t('forms_login_buttons_submit_label')}
            </Button>
        </Box>
    );
    usePageTracking(
        true,
        mode === 'login' ? 'connexion' : mode === 'register' ? 'inscription' : 'autre',
    );

    return (
        <>
            {mode === 'login' && (
                <ActionFullScreenLayout
                    form={LoginForm}
                    formProps={{
                        loggedInGuest: false,
                        onAuthTokensChange: handleAuthTokensChange,
                        onCancelClick: handleCancelClick,
                        onForgotPasswordClick: handleForgotPasswordClick,
                        onRegisterClick: handleRegisterClick,
                        onMustUpdatePassword: handleMustUpdatePassword,
                        registerLink: false,
                    }}
                    disableClose
                    enableLogoClick
                    image="login_image"
                    logoPosition="top"
                    header={registerButton}
                    title={t('panels_login_title')}
                    description={t('panels_login_description')}
                />
            )}
            {mode === 'forgot-password' && (
                <ActionFullScreenLayout
                    form={ForgotPasswordForm}
                    onFormUpdate={handleForgotPasswordSuccess}
                    formProps={{
                        onCancelClick: handleCancelClick,
                    }}
                    disableClose
                    enableLogoClick
                    image="login_image"
                    logoPosition="top"
                    title={t('panels_forgotpassword_title')}
                    description={t('panels_forgotpassword_description')}
                    infos="register_special_info"
                />
            )}
            {mode === 'forgot-password-success' && (
                <ActionFullScreenLayout
                    form={InfosPanelForm}
                    formProps={{
                        onCancelClick: handleCancelClick,
                        buttonLabel: t('forms_forgotpassword_buttons_cancel_label'),
                    }}
                    disableClose
                    enableLogoClick
                    image="login_image"
                    logoPosition="top"
                    title={t('panels_forgotpassword_success_title')}
                    description={t('forms_forgotpassword_verify_inbox_text')}
                />
            )}
            {mode === 'register' && (
                <ActionFullScreenLayout
                    form={RegisterForm}
                    onFormUpdate={handleRegisterSuccess}
                    formProps={{
                        loginLink: false,
                        onCancelClick: handleCancelClick,
                    }}
                    disableClose
                    enableLogoClick
                    image="login_image"
                    logoPosition="top"
                    header={loginButton}
                    title={t('panels_register_title')}
                    description={t('panels_register_description')}
                    infos="register_special_info"
                />
            )}
            {mode === 'register-success' && (
                <ActionFullScreenLayout
                    form={InfosPanelForm}
                    formProps={{
                        onCancelClick: handleRegisterSuccessClick,
                        loginLink: false,
                        buttonLabel: t('forms_login_buttons_submit_label'),
                        alert: t('forms_register_alert_inbox_text'),
                    }}
                    disableClose
                    enableLogoClick
                    image="login_image"
                    logoPosition="top"
                    title={t('panels_register_success_title')}
                    description={t('forms_register_verify_inbox_text')}
                />
            )}
        </>
    );
}

export default LoginScreen;
