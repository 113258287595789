import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useTheme} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import {
    useLuniiUserTokens,
    useLuniiTranslation,
    ShadowedPanel,
    LoginPanel,
    FormPanel,
    RegisterForm,
    ForgotPasswordForm,
    LargeTitleText,
    Row,
    InfosPanelForm,
    Button,
    useShoppingCart,
    useLuniiSpa,
    useLuniiCheckoutTracking,
    useLuniiUser,
    useLuniiPasswordUpdateModal,
    MergeAccountWarningModal,
    ProductTableNameEnum,
} from '../../../ui';
import {CheckoutLayout} from '../../layouts';
import {getRedirectLanguage} from '../../../utils';
import {useCheckoutTracking} from '../../../hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2, 6, 2),
        },
        backgroundColor: 'white',
    },
    container: {
        paddingBottom: theme.spacing(8),
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    left: {
        position: 'relative',
        '& >*': {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: 128,
        },
    },
    right: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& >*': {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        },
    },
    button: {
        margin: 'auto',
        marginBottom: theme.spacing(2),
        justifySelf: 'right',
    },
}));

// todo later possible refactor for panels
export function LoginScreen() {
    useCheckoutTracking(true, 'identification');
    const dataLayerCheckoutRegistrationEvent = useLuniiCheckoutTracking({envModel: 'inscription'});
    const dataLayerCheckoutConnectionEvent = useLuniiCheckoutTracking({envModel: 'identification'});
    const classes = useStyles();
    const theme = useTheme();
    const {open: openPasswordUpdateModal} = useLuniiPasswordUpdateModal();
    const [{cart}] = useShoppingCart();
    const {user} = useLuniiUser();
    const {t} = useLuniiTranslation();
    const {navigate} = useLuniiSpa();
    const cartCountry = useMemo(
        () =>
            (cart || {}).country ||
            getRedirectLanguage(typeof navigator !== 'undefined' ? navigator : {}),
        [],
    ); // do not reset if cart is reseted
    const xsDevice = useMediaQuery(theme.breakpoints.only('xs'));
    const tbDevice = useMediaQuery(theme.breakpoints.only('md'));
    const [mode, setMode] = useState('login');
    const {setCurrentUserTokens} = useLuniiUserTokens();
    const handleForgotPasswordClick = useCallback(() => setMode('forgot-password'), [setMode]);
    const [openModal, setOpenModal] = useState<{open: boolean, mode?: 'fah' | 'flam' | 'fah_flam'}>({open: false});
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const handleNextStep = useCallback(() => {
        if (!user) return; // if !user => do nothing
        const fahPacksInCart = cart?.items?.some((item) => item.type === ProductTableNameEnum.Packs && item.deviceTarget?.indexOf('fah') !== -1);
        const flamPacksInCart = cart?.items?.some((item) => item.type === ProductTableNameEnum.Packs && item.deviceTarget?.indexOf('flam') !== -1);
        const deviceTarget: string[] = [];
        if (!user.hasFahs && fahPacksInCart) deviceTarget.push('fah'); // usecase => user has no fah but has fah audiobooks in his cart
        if (!user.hasFlam && flamPacksInCart) deviceTarget.push('flam'); // usecase => user has no flam but has flam audiobooks in his cart
        if (deviceTarget.length > 0) {
            !openModal.open && setOpenModal({open: true, mode: deviceTarget.join('_') as 'fah' | 'flam' | 'fah_flam'});
        } else {
            // default => go to payment page
            openModal.open && setOpenModal({open: false, mode: undefined});
            navigate('/payment', {replace: true});
        }
    }, [user, navigate, cart?.items]);
    useEffect(() => {
        // called when page is loaded and when user is changed
        if (user) {
            // user is already connected => go to cart page || user has just connected => handle next step
            firstLoad ? navigate('/cart') : handleNextStep();
        }
        firstLoad && setFirstLoad(false);
    }, [user]);
    const setRegisterMode = useCallback(() => {
        setMode('register');
        dataLayerCheckoutRegistrationEvent();
    }, [setMode, dataLayerCheckoutRegistrationEvent]);
    const setLoginMode = useCallback(() => {
        setMode('login');
        dataLayerCheckoutConnectionEvent();
    }, [setMode, dataLayerCheckoutConnectionEvent]);
    const handleForgotPasswordSuccess = useCallback(() => setMode('forgot-password-success'), []);
    const handleRegisterSuccess = useCallback(() => setMode('register-success'), []);
    const handleAuthTokensChange = useCallback(
        async (tokens) => {
            await setCurrentUserTokens(tokens);
        },
        [setCurrentUserTokens],
    );
    const handleMustUpdatePassword = useCallback(
        async (loginData) => {
            openPasswordUpdateModal(loginData);
        },
        [openPasswordUpdateModal],
    );
    const closeModal = () => {
        openModal.open && setOpenModal({open: false, mode: undefined});
    };
    const goToCartPage = () => {
        closeModal();
        navigate('/cart');
    };
    const goToPaymentPage = () => {
        closeModal();
        navigate('/payment', {replace: true});
    };
    return (
        <CheckoutLayout>
            <Row
                padding="default-top"
                alignItems="flex-start"
                justify="space-between"
                rootClassName={classes.container}
            >
                <Grid
                    item
                    container
                    lg={4}
                    md={5}
                    sm={12}
                    className={classes.left}
                    direction="column"
                >
                    <HiddenCss smDown>
                        <LargeTitleText className={classes.title as any}>
                            {t('checkout_login_title')}
                        </LargeTitleText>
                    </HiddenCss>
                </Grid>
                <Grid
                    item
                    container
                    lg={7}
                    md={6}
                    sm={12}
                    className={classes.right}
                    direction="column"
                >
                    <HiddenCss mdUp>
                        <LargeTitleText className={classes.title as any}>
                            {t('checkout_login_title')}
                        </LargeTitleText>
                    </HiddenCss>
                    <ShadowedPanel className={classes.root}>
                        {mode === 'login' && (
                            <LoginPanel
                                dense
                                disableClose
                                title={t('checkout_login_form_title')}
                                titleInHeader={!xsDevice}
                                header={() => (
                                    <Button
                                        className={classes.button}
                                        color="secondary"
                                        size="small"
                                        onClick={setRegisterMode}
                                    >
                                        {t('buttons_register_label')}
                                    </Button>
                                )}
                                logoPosition="default"
                                formProps={{
                                    loggedInGuest: false,
                                    registerLink: false,
                                    inputFullWidth: xsDevice || tbDevice,
                                    onAuthTokensChange: handleAuthTokensChange,
                                    onForgotPasswordClick: handleForgotPasswordClick,
                                    onMustUpdatePassword: handleMustUpdatePassword,
                                }}
                            />
                        )}
                        {mode === 'forgot-password' && (
                            <FormPanel
                                title={t('panels_forgotpassword_title')}
                                onFormUpdate={handleForgotPasswordSuccess}
                                description={t('panels_forgotpassword_description')}
                                infos="register_special_info"
                                disableClose
                                dense
                                formProps={{
                                    onCancelClick: setLoginMode,
                                    inputFullWidth: xsDevice || tbDevice,
                                }}
                                form={ForgotPasswordForm}
                            />
                        )}
                        {mode === 'forgot-password-success' && (
                            <FormPanel
                                title={t('panels_forgotpassword_success_title')}
                                description={t('forms_forgotpassword_verify_inbox_text')}
                                disableClose
                                dense
                                formProps={{
                                    onCancelClick: setLoginMode,
                                    buttonLabel: t('forms_forgotpassword_buttons_cancel_label'),
                                    inputFullWidth: xsDevice || tbDevice,
                                }}
                                form={InfosPanelForm}
                            />
                        )}
                        {mode === 'register' && (
                            <FormPanel
                                title={t('panels_register_title')}
                                onFormUpdate={handleRegisterSuccess}
                                description={t('panels_register_description')}
                                infos="register_special_info"
                                header={() => (
                                    <Button
                                        className={classes.button}
                                        color="secondary"
                                        size="small"
                                        onClick={setLoginMode}
                                    >
                                        {t('forms_login_buttons_submit_label')}
                                    </Button>
                                )}
                                disableClose
                                dense
                                formProps={{
                                    forcedCountry: cartCountry,
                                    loginLink: false,
                                    onCancelClick: setLoginMode,
                                    inputFullWidth: xsDevice || tbDevice,
                                }}
                                form={RegisterForm}
                            />
                        )}
                        {mode === 'register-success' && (
                            <FormPanel
                                title={t('panels_register_success_title')}
                                description={t('forms_register_verify_inbox_text')}
                                disableClose
                                dense
                                formProps={{
                                    onCancelClick: setLoginMode,
                                    loginLink: false,
                                    buttonLabel: t('forms_login_buttons_submit_label'),
                                    alert: t('forms_register_alert_inbox_text'),
                                    inputFullWidth: xsDevice || tbDevice,
                                }}
                                form={InfosPanelForm}
                            />
                        )}
                    </ShadowedPanel>
                </Grid>
            </Row>
            <MergeAccountWarningModal
                isOpen={openModal.open}
                mode={openModal.mode}
                onClose={goToCartPage}
                onConfirm={goToPaymentPage}
                onLogout={closeModal}
            />
        </CheckoutLayout>
    );
}

export default LoginScreen;
