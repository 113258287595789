import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import {Trans} from 'react-i18next';
import {cssBorder, cssText} from '@ohoareau/css-utils';
import {Grid, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {
    address,
    AddressForm,
    AddressTypeEnum,
    Button,
    buttonifyFromProps,
    Checkbox,
    convertPrice,
    CreditCardForm,
    ErrorPanel,
    Illustration,
    Row,
    ShadowedPanel,
    Spinner,
    SubscriptionCategory,
    useLuniiNavigation,
    useLuniiPage,
    useLuniiSpa,
    useLuniiTranslation,
    useLuniiUser,
    useSubscription,
    useSubscriptionFreeMonth,
    useSubscriptionRetrievePaymentIntent,
    WarningPanel,
} from '../../../ui';
import {
    usePageTracking,
    useSubscriptionPayment,
    useSubscriptionPaymentFreeMonth,
} from '../../../hooks';
import {LuniiSubscriptionCheckoutContext, SubscriptionCheckoutActionType} from '../../../contexts';
import {SubscriptionRecap} from './components';
import {dateOneMonthFromToday, literaryDateFormat} from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    panel: {
        padding: theme.spacing(3, 2),
        marginBottom: theme.spacing(3),
        backgroundColor: 'white',
        ...cssBorder(theme, 'push'),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    },
    heading: {
        ...cssText(theme, 'standard', 'large_title'),
        margin: theme.spacing(1, 0, 4),
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
            margin: theme.spacing(2, 0, 4),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
        },
    },
    creditCardForm: {
        padding: 0,
    },
    actions: {
        width: '100%',
    },
    errors: {
        marginBottom: theme.spacing(2),
    },
    errorPanel: {
        marginBottom: theme.spacing(1),
    },
    checkboxes: {
        '& >*': {
            margin: theme.spacing(0, 0, 2),
        },
        marginBottom: theme.spacing(2),
    },
    checkboxLink: {
        ...cssText(theme, 'standard', 'button_1_plain'),
        fontWeight: '800',
        margin: theme.spacing(0, 0, 1, 0),
    },
    recap: {
        ...cssText(theme, 'standard', 'secondary_body'),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    recapBody: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        '& span': {
            ...cssText(theme, 'standard', 'secondary_body_thick'),
            paddingLeft: '10px',
        },
        '& >*': {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(0.5),
        },
        [theme.breakpoints.up('sm')]: {
            '& >*': {
                display: 'table-row',
                paddingBottom: theme.spacing(0.5),
            },
            '& >*>*': {
                display: 'table-cell',
            },
        },
    },
    recapActions: {
        padding: 0,
    },
    paymentform: {
        '& h2': {
            ...cssText(theme, 'standard', 'title_1'),
            margin: theme.spacing(0, 0, 1),
        },
        '& p': {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        },
    },
    button: {
        width: '100%',
        textAlign: 'center',
        marginTop: theme.spacing(1),
    },
    paymentWait: {
        ...cssText(theme, 'standard', 'title_2'),
    },
}));

enum PaymentStateEnum {
    IDLE,
    IN_PROGRESS,
    SUCCESSFUL,
}

/**
 * Payment Flow:
 * 1. Call pay on click
 *      paymentInProgress = true
 * 2. Retrieve payment intent infos from server
 * 3. Validate payment from client
 *      paymentSuccessfull = true
 * 4. Fetch Subscription
 * 5. If client has subscription > set payment ok && refresh user
 *      subscriptionConfirmed = true
 * 6. Else poll untill
 */
export function BillingScreen() {
    usePageTracking(true, 'abonnement_paiement');
    const classes = useStyles();
    const {t, i18n, exists} = useLuniiTranslation();
    const {user, refreshUser} = useLuniiUser();
    const stripe = useStripe();
    const elements = useElements();
    const formCreditCardOwner = useForm({mode: 'onChange'});
    const {navigate} = useLuniiSpa();
    const {locale} = useLuniiPage() || {};
    const {goPageByModel} = useLuniiNavigation();

    const {state: subscriptionCheckoutState, dispatch: subscriptionCheckoutDispatch} = useContext(
        LuniiSubscriptionCheckoutContext,
    );

    useEffect(() => {
        if (!subscriptionCheckoutState.selected) {
            navigate('/');
        }
    }, []);

    const selectedSubscription = useMemo(
        () => subscriptionCheckoutState?.selected,
        [subscriptionCheckoutState?.selected],
    );
    const [cardComplete, setCardComplete] = useState<boolean>(false);
    const [subscriptionPayable, setSubscriptionPayable] = useState<boolean>(false);
    const [confirmSelectedSubscription, setConfirmSelectedSubscription] = useState<boolean>(false);
    const [confirmNoGift, setConfirmNoGift] = useState<boolean>(false);
    const [paymentState, setPaymentState] = useState<PaymentStateEnum>(PaymentStateEnum.IDLE);
    const [polling, setPolling] = useState<boolean>(false);
    const [validatePaymentError, setValidatePaymentMethodError] = useState<any>(undefined);
    const [paymentMethodError, setPaymentMethodError] = useState<any>(undefined);

    const [onRetrieveSubscriptionPaymentIntent, {error: retrieveSubscriptionPaymentIntentError}] =
        useSubscriptionRetrievePaymentIntent();
    const [onRetrieveSubscriptionSetupIntent, {error: retrieveSubscriptionSetupIntentError}] =
        useSubscriptionFreeMonth({});

    const [createSubscriptionPayment, validatePayment] = useSubscriptionPayment(
        onRetrieveSubscriptionPaymentIntent,
        stripe,
        elements,
    );
    const [createSubscriptionPaymentFreeMonth, validatePaymentFreeMonth] =
        useSubscriptionPaymentFreeMonth(onRetrieveSubscriptionSetupIntent, stripe, elements);

    const [
        {retrieveUserSubscriptionExecute: subscriptionFind},
        {
            retrieveUserSubscriptionResult: {
                data: subscriptionFindData,
                startPolling: subscriptionFindStartPolling,
                stopPolling: subscriptionFindStopPolling,
            },
        },
    ] = useSubscription({
        retrieveUserSubscriptionOptions: {
            onError: () => {
                if (polling) {
                    subscriptionFindStopPolling();
                    setPolling(false);
                }
            },
            pollInterval: 2000,
        },
    });

    const billingInfos = useMemo(
        () => user?.billingInfos?.find((adr: address) => adr.locale === user?.locale),
        [user?.billingInfos, user?.locale],
    );

    const userHadFreeMonth = useMemo(
        () => user?.hadMonthlySubscriptionFreeMonth,
        [user?.hadMonthlySubscriptionFreeMonth],
    );

    const handleSubscriptionPay = useCallback(async () => {
        if (paymentState === PaymentStateEnum.IN_PROGRESS) return;
        setPaymentState(PaymentStateEnum.IN_PROGRESS);
        setValidatePaymentMethodError(undefined);
        setPaymentMethodError(undefined);
        try {
            const name = formCreditCardOwner.getValues('cardOwner');
            const onSuccess = async () => {
                if (subscriptionFind) subscriptionFind();
                setPaymentState(PaymentStateEnum.SUCCESSFUL);
                subscriptionFindStartPolling(2000);
                setPolling(true);
            };
            const onError = async (createPaymentError: any) => {
                if (createPaymentError) setPaymentMethodError(createPaymentError);
                setPaymentState(PaymentStateEnum.IDLE);
                subscriptionFindStopPolling();
                setPolling(false);
            };
            if (
                user &&
                !userHadFreeMonth &&
                selectedSubscription?.category === SubscriptionCategory.MONTHLY
            ) {
                const setupIntentInfosResult = await createSubscriptionPaymentFreeMonth(
                    user?.email,
                    name,
                    selectedSubscription!!.stripeProductReference,
                    billingInfos,
                    locale,
                );
                await validatePaymentFreeMonth(
                    setupIntentInfosResult?.data?.retrieveSubscriptionSetupIntentFreeMonth,
                    name,
                    billingInfos,
                    onSuccess,
                    onError,
                );
            } else {
                const paymentIntentInfos = await createSubscriptionPayment(
                    user?.email,
                    name,
                    selectedSubscription!!.stripeProductReference,
                    billingInfos,
                    locale,
                );
                await validatePayment(paymentIntentInfos, name, billingInfos, onSuccess, onError);
            }
        } catch (e) {
            setValidatePaymentMethodError(e);
            setPaymentState(PaymentStateEnum.IDLE);
        }
    }, [
        billingInfos,
        formCreditCardOwner,
        paymentState,
        setPolling,
        selectedSubscription,
        subscriptionFind,
        subscriptionFindStartPolling,
        subscriptionFindStopPolling,
        createSubscriptionPayment,
        createSubscriptionPaymentFreeMonth,
        validatePayment,
        validatePaymentFreeMonth,
        user,
        userHadFreeMonth,
        locale,
    ]);

    const handleSubscriptionSuccess = useCallback(async () => {
        subscriptionCheckoutDispatch({type: SubscriptionCheckoutActionType.CHECKOUT_SUCCESS});
        refreshUser();
        await navigate('/confirmation');
    }, [navigate, refreshUser, subscriptionCheckoutDispatch]);

    // Handles disable status of payment button
    useEffect(() => {
        if (
            cardComplete &&
            selectedSubscription &&
            billingInfos &&
            confirmSelectedSubscription &&
            confirmNoGift
        ) {
            setSubscriptionPayable(true);
        } else if (
            !cardComplete ||
            !billingInfos ||
            !selectedSubscription ||
            !confirmSelectedSubscription ||
            !confirmNoGift
        ) {
            setSubscriptionPayable(false);
        }
    }, [
        cardComplete,
        selectedSubscription,
        billingInfos,
        confirmSelectedSubscription,
        confirmNoGift,
    ]);

    // Handles the fetching of the subscription to confirm the purchase success
    useEffect(() => {
        const onSuccessNavigate = async () => {
            await handleSubscriptionSuccess();
        };
        if (
            paymentState === PaymentStateEnum.SUCCESSFUL &&
            !subscriptionCheckoutState.success &&
            subscriptionFindData?.retrieveUserSubscription
        ) {
            subscriptionFindStopPolling();
            setPolling(false);
            onSuccessNavigate();
        }
    }, [
        handleSubscriptionSuccess,
        paymentState,
        polling,
        subscriptionCheckoutState.success,
        subscriptionFindData?.retrieveUserSubscription,
        subscriptionFindStopPolling,
    ]);

    const updateConfirmSelectedSubscription = (e: any) => {
        setConfirmSelectedSubscription(e?.target?.checked);
    };

    const updateConfirmNoGift = (e: any) => {
        setConfirmNoGift(e?.target?.checked);
    };

    const onCardComplete = () => {
        setCardComplete(true);
    };

    const handleLegalClick = (e: any) => {
        e.preventDefault();
        goPageByModel && goPageByModel('mentions_legales', true);
    };

    const subscriptionRecapSuffix = `${selectedSubscription?.reference}${
        !userHadFreeMonth && selectedSubscription?.category === SubscriptionCategory.MONTHLY
            ? '_free_month'
            : ''
    }`;
    const {Button: SubscriptionRecapActionButton} = buttonifyFromProps({
        buttonLabel: t(`subscription_recap_action`),
        buttonType: 'tertiary',
        buttonTarget: () => navigate('/'),
    });

    const subscriptionRecapDate =
        !userHadFreeMonth && selectedSubscription?.category === SubscriptionCategory.MONTHLY
            ? literaryDateFormat(locale, dateOneMonthFromToday())
            : t('today');

    const subscriptionRecap = selectedSubscription && (
        <ShadowedPanel className={classes.panel}>
            <SubscriptionRecap
                title={t(`subscription_recap_title_${subscriptionRecapSuffix}`, {
                    price: convertPrice(
                        selectedSubscription?.price,
                        selectedSubscription?.currency,
                    ),
                })}
                subtitle={t(`subscription_recap_subtitle_${subscriptionRecapSuffix}`, {
                    price: convertPrice(
                        selectedSubscription?.price,
                        selectedSubscription?.currency,
                    ),
                })}
                body={
                    <div className={classes.recapBody}>
                        <p>
                            {t(`subscription_recap_startdate_caption_${subscriptionRecapSuffix}`, {
                                price: convertPrice(
                                    selectedSubscription?.price,
                                    selectedSubscription?.currency,
                                ),
                            })}
                            <span>{t('today')}</span>
                        </p>
                        <p>
                            {t(`subscription_recap_enddate_caption_${subscriptionRecapSuffix}`, {
                                price: convertPrice(
                                    selectedSubscription?.price,
                                    selectedSubscription?.currency,
                                ),
                            })}
                            <span>{subscriptionRecapDate}</span>
                        </p>
                    </div>
                }
                actions={
                    (SubscriptionRecapActionButton && (
                        <SubscriptionRecapActionButton className={classes.recapActions} noPadding/>
                    )) as React.ReactNode
                }
                aside={
                    <Illustration
                        mt={1}
                        name="calendar"
                    />
                }
            />
        </ShadowedPanel>
    );

    return (
        <div className={classes.root}>
            {paymentState !== PaymentStateEnum.IDLE && (
                <Spinner fixed>
                    <p className={classes.paymentWait}>{t('checkout_payment_wait')}</p>
                </Spinner>
            )}
            <Row
                padding="default"
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Grid
                    item
                    lg={6}
                    md={8}
                    sm={12}
                >
                    <div className={classes.heading}>{t('subscription_title_billing_step')}</div>
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={8}
                    sm={12}
                >
                    {subscriptionRecap}
                    <ShadowedPanel className={classes.panel}>
                        <AddressForm
                            key="billing"
                            title={t('subscription_billingAddress_form_title')}
                            type={AddressTypeEnum.Billing}
                            locale={user?.locale}
                        />
                    </ShadowedPanel>
                    <ShadowedPanel className={clsx(classes.panel, classes.paymentform)}>
                        <h2>{t('subscription_paymentform_title')}</h2>
                        <p>{t('subscription_paymentform_info')}</p>
                        <FormProvider {...formCreditCardOwner}>
                            <CreditCardForm
                                className={classes.creditCardForm}
                                onCardComplete={onCardComplete}
                            />
                        </FormProvider>
                    </ShadowedPanel>
                    <div className={classes.actions}>
                        <div className={classes.checkboxes}>
                            <Checkbox
                                id="subscription_confirm_purchase"
                                name="subscription_confirm_purchase"
                                status={confirmSelectedSubscription}
                                content={
                                    <Trans
                                        i18n={i18n}
                                        i18nKey="subscription_checkbox_terms"
                                    >
                                        ...
                                        <span
                                            onClick={handleLegalClick}
                                            className={classes.checkboxLink}
                                        >
                                        ...
                                    </span>
                                        ...
                                    </Trans>
                                }
                                linkTarget={updateConfirmSelectedSubscription}
                                onChange={updateConfirmSelectedSubscription}
                            />
                            <Checkbox
                                id="subscription_confirm_gift"
                                name="subscription_confirm_gift"
                                alignItems="flex-start"
                                status={confirmNoGift}
                                content={t('subscription_checkbox_gift', {email: user?.email})}
                                linkTarget={updateConfirmNoGift}
                                onChange={updateConfirmNoGift}
                            />
                        </div>
                        <div
                            className={clsx(
                                (retrieveSubscriptionPaymentIntentError ||
                                    retrieveSubscriptionSetupIntentError ||
                                    validatePaymentError ||
                                    paymentMethodError) &&
                                classes.errors,
                            )}
                        >
                            {retrieveSubscriptionPaymentIntentError && (
                                <ErrorPanel
                                    className={classes.errorPanel}
                                    group="subscription"
                                    error={retrieveSubscriptionPaymentIntentError}
                                />
                            ) || false}
                            {retrieveSubscriptionSetupIntentError && (
                                <ErrorPanel
                                    className={classes.errorPanel}
                                    group="subscription"
                                    error={retrieveSubscriptionSetupIntentError}
                                />
                            ) || false}
                            {validatePaymentError && !retrieveSubscriptionPaymentIntentError && !retrieveSubscriptionSetupIntentError && (
                                <ErrorPanel
                                    className={classes.errorPanel}
                                    group="subscription"
                                    error={validatePaymentError}
                                />
                            ) || false}
                            {paymentMethodError && (
                                <ErrorPanel
                                    className={classes.errorPanel}
                                    error={paymentMethodError}
                                    group="stripe"
                                />
                            ) || false}
                        </div>
                        {exists('checkout_subscription_cart_global_message') && (
                            <WarningPanel
                                type="message"
                                transKey="checkout_subscription_cart_global_message"
                            />
                        )}
                        <div>
                            <Button
                                className={classes.button}
                                onClick={handleSubscriptionPay}
                                color="primary"
                                disabled={!subscriptionPayable}
                            >
                                {t('subscription_pay')}
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Row>
        </div>
    );
}

export default BillingScreen;
