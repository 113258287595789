import React, {useContext, useEffect, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssBorder, cssText} from '@ohoareau/css-utils';
import {Grid} from '@material-ui/core';
import {HistoryLocation, useLocation} from '@reach/router';
import {
    Button,
    convertPrice,
    Illustration,
    Row,
    SubscriptionCategory,
    SubscriptionProduct,
    useLuniiEventTracking, useLuniiPage,
    useLuniiSpa,
    useLuniiTranslation,
    useLuniiUser,
    useSubscriptionItems,
} from '../../../ui';
import {usePageTracking} from '../../../hooks';
import {SubscriptionPicker} from './components';
import {LuniiSubscriptionCheckoutContext, SubscriptionCheckoutActionType} from '../../../contexts';
import {Alert} from '../..';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    panel: {
        padding: theme.spacing(3, 2),
        marginBottom: theme.spacing(3),
        backgroundColor: 'white',
        ...cssBorder(theme, 'push'),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    },
    spinner: {
        position: 'fixed',
    },
    heading: {
        ...cssText(theme, 'standard', 'large_title'),
        margin: theme.spacing(1, 0, 4),
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
            margin: theme.spacing(2, 0, 4),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
        },
    },
    alert: {
        margin: theme.spacing(0, 0, 4),
    },
    subscriptionWarning: {
        color: '#04384A',
    },
    paymentform: {
        '& h2': {
            ...cssText(theme, 'standard', 'title_1'),
            margin: theme.spacing(0, 0, 1),
        },
        '& p': {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        },
    },
    paymentWait: {
        ...cssText(theme, 'standard', 'title_2'),
    },
    actions: {
        textAlign: 'center',
    },
    illustration: {
        width: '90%',
        maxWidth: 404,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    }
}));

export function SelectScreen() {
    usePageTracking(true, 'abonnement_selection');
    const classes = useStyles();
    const {state: subscriptionCheckoutState, dispatch: subscriptionCheckoutDispatch} = useContext(
        LuniiSubscriptionCheckoutContext,
    );
    const location: HistoryLocation = useLocation();
    const {t, exists} = useLuniiTranslation();
    const {user} = useLuniiUser();
    const {locale} = useLuniiPage() || {};
    const {navigate} = useLuniiSpa();
    const handleEventTracking = useLuniiEventTracking();
    const [items, setItems] = useState<
        Array<{title: string; pretitle?: string; subtitle?: string; body?: any} & any>
    >([]);
    const [
        {retrieveSubscriptionProductsExecute},
        {
            retrieveSubscriptionProductsResult: {
                data: subscriptionsProducts,
                loading: subscriptionFindProductsLoading,
            },
        },
    ] = useSubscriptionItems({
        retrieveProductsOptions: {
            variables: {countryCode: locale},
            onCompleted: (data) => {
                setItems(
                    data?.retrieveSubscriptionProducts?.items?.map(
                        (subscription: SubscriptionProduct) => {
                            const {reference, price, currency, category} = subscription || {};
                            if (user && user.hadMonthlySubscriptionFreeMonth) {
                                return {
                                    ...subscription,
                                    title: t(`subscription_product_${reference}_title`, {
                                        price: convertPrice(price, currency),
                                    }),
                                    pretitle: t(`subscription_product_${reference}_pretitle`, {
                                        price: convertPrice(price, currency),
                                    }),
                                    body: t(`subscription_product_${reference}_body_new`, {
                                        price: convertPrice(price, currency),
                                        ...(category === SubscriptionCategory.ANNUAL && {
                                            monthlyPrice: convertPrice(
                                                Math.ceil(price / 12),
                                                currency,
                                            ),
                                        }),
                                        ...(category === SubscriptionCategory.MONTHLY && {
                                            annualPrice: convertPrice(
                                                Math.ceil(price * 12),
                                                currency,
                                            ),
                                        }),
                                    }),
                                    bottomNote: exists(`subscription_product_${reference}_bottom_note`) && t(`subscription_product_${reference}_bottom_note`),
                                };
                            }
                            return {
                                ...subscription,
                                title: t(`subscription_product_${reference}_title_free_month_new`, {
                                    price: convertPrice(price, currency),
                                }),
                                pretitle: t(`subscription_product_${reference}_pretitle_free_month`),
                                subtitle: t(
                                    `subscription_product_${reference}_subtitle_free_month`,
                                    {
                                        price: convertPrice(price, currency),
                                        ...(category === SubscriptionCategory.ANNUAL && {
                                            monthlyPrice: convertPrice(
                                                Math.ceil(price / 12),
                                                currency,
                                            ),
                                        }),
                                        ...(category === SubscriptionCategory.MONTHLY && {
                                            annualPrice: convertPrice(
                                                Math.ceil(price * 12),
                                                currency,
                                            ),
                                        }),
                                    },
                                ),
                                body: t(`subscription_product_${reference}_body_new`, {
                                    price: convertPrice(price, currency),
                                    ...(category === SubscriptionCategory.ANNUAL && {
                                        monthlyPrice: convertPrice(Math.ceil(price / 12), currency),
                                    }),
                                    ...(category === SubscriptionCategory.MONTHLY && {
                                        annualPrice: convertPrice(Math.ceil(price * 12), currency),
                                    }),
                                }),
                                bottomNote: exists(`subscription_product_${reference}_bottom_note_free_month`) && t(`subscription_product_${reference}_bottom_note_free_month`),
                            };
                        },
                    ) || [],
                );
            },
        },
    });
    useEffect(() => {
        const execute = async () => {
            if (retrieveSubscriptionProductsExecute) {
                await retrieveSubscriptionProductsExecute();
            }
        };
        execute();
    }, [retrieveSubscriptionProductsExecute, locale]);
    useEffect(() => {
        if (
            subscriptionsProducts &&
            location?.search &&
            undefined !== window &&
            !subscriptionCheckoutState.selected
        ) {
            const params = new URLSearchParams(location.search);
            const selectedSubscriptionRef = params.get('selected');
            if (!selectedSubscriptionRef) return;
            const selectedSubscriptionProduct = items?.find(
                (subscription: SubscriptionProduct) =>
                    // pre-select category, carefull, only works if there is only one monthly and one annual category
                    subscription.category && selectedSubscriptionRef.indexOf(subscription.category) !== -1,
            );
            if (selectedSubscriptionProduct) {
                subscriptionCheckoutDispatch({
                    type: SubscriptionCheckoutActionType.UPDATE_SELECTED_SUBSCRIPTION,
                    payload: {
                        selected: selectedSubscriptionProduct,
                    },
                });
            }
        }
    }, [items]);
    const handleSubscriptionSelect = (reference: string) => {
        const subscription = subscriptionsProducts?.retrieveSubscriptionProducts?.items?.find(
            (subscriptionProduct: SubscriptionProduct) =>
                subscriptionProduct.reference === reference,
        );
        if (subscription) {
            subscriptionCheckoutDispatch({
                type: SubscriptionCheckoutActionType.UPDATE_SELECTED_SUBSCRIPTION,
                payload: {
                    selected: subscription,
                },
            });
        }
    };
    const handleValidate = () => {
        if (!subscriptionCheckoutState.selected) return;
        const product = {
            ...subscriptionCheckoutState.selected,
            quantity: 1,
        };
        const trackingEvent: any = {
            categorie: 'ecommerce',
            action: 'ajout_panier',
            valeur: product.price,
        };
        handleEventTracking(trackingEvent, product, 'abonnement_presentation');
        navigate('/billing');
    };
    return (
        <Row direction='column' alignItems='center' justify='center' rootClassName={classes.root}>
            <Grid item lg={9} md={12} sm={12}>
                <div className={classes.heading}>{t('subscription_title_select_step')}</div>
                {user?.hadMonthlySubscriptionFreeMonth && <Alert className={classes.alert} description={t('subscription_warning_message_free_month_already_used')} theme='info' icon='info-24'/> || false}
                <SubscriptionPicker
                    items={items}
                    picked={subscriptionCheckoutState?.selected?.reference}
                    loading={subscriptionFindProductsLoading && !(items.length > 0)}
                    onItemPicked={handleSubscriptionSelect}
                />
                <div className={classes.actions}>
                    <Button onClick={handleValidate} color='primary' disabled={!subscriptionCheckoutState.selected}>
                        {t('subscription_select')}
                    </Button>
                    <Illustration name='subscription_credit_calendar' className={classes.illustration} />
                </div>
            </Grid>
        </Row>
    );
}

export default SelectScreen;
