import {useLuniiQueryApi, useLuniiUser} from '../../../ui/hooks';
import {catalogToLocale, getAudiobookProductPageUrl, getTargetDevice} from '../../../utils';
import {useRetrieveAlbumsPagePaths} from '../../index';

export function useUserAudiobooks(target?: 'fah' | 'flam') {
    const {user} = useLuniiUser();
    const albumPagePaths = useRetrieveAlbumsPagePaths();
    let query;
    let response;
    switch (target) {
        case 'fah':
            query = 'GET_USER_FAH_AUDIOBOOKS';
            response = 'getUserFahAudiobooks'
            break;
        case 'flam':
            query = 'GET_USER_FLAM_AUDIOBOOKS';
            response = 'getUserFlamAudiobooks';
            break;
        default:
            query = 'GET_USER_AUDIOBOOKS';
            response = 'getUserAudiobooks';
            break;
    }
    const {loading, error, data, ...infos} = useLuniiQueryApi(query, {
        skip: !user,
    });
    return {
        audiobooks: (((data || {})[response] || {}).items || [])
            .map((audiobook: any) => {
                const url = user?.locale && `${getAudiobookProductPageUrl(catalogToLocale(user?.locale)!!, getTargetDevice(audiobook.deviceTarget) as any)}/${audiobook.slug}/`;
                return {
                    ...audiobook,
                    target: albumPagePaths.indexOf(url) !== -1 && url,
                }
            }),
        loading,
        error: error || false,
        ...infos,
    };
}

export default useUserAudiobooks;
