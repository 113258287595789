import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import {navigate} from 'gatsby-link';
import clsx from 'clsx';
import {digital_album_product, DropDownLanguage, DynamicIcon, Img, useLuniiEventTracking, useLuniiTranslation} from '../../../ui';
import {getThemeColor} from '../../../../configs';
import {getTargetDevice} from '../../../utils';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 16,
        boxShadow: '0px 2px 8px 0px rgba(66, 63, 55, 0.10)',
    },
    deviceTarget: ({color, backgroundColor}: any) => ({
        display: 'flex',
        alignItems: 'center',
        backgroundColor,
        padding: '8px 8px 8px 12px',
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, color),
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    }),
    icon: {
        maxWidth: 24,
        width: 24,
        maxHeight: 24,
        height: 24,
        marginRight: 8,
    },
    content: {
        padding: theme.spacing(2),
    },
    infos: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&>*:not(:last-child)': {
            marginRight: theme.spacing(5),
        },
    },
    title: {
        ...cssText(theme, 'standard', 'overline_title_2', undefined, undefined, '#5F6769'),
        marginBottom: 4,

    },
    text: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2C3637'),
    },
    types: {
        marginTop: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    image: {
        minHeight: 60,
        maxHeight: 60,
        width: 'auto !important',
    }
}));

const getProperties = (mode: string | undefined) => {
    switch (mode) {
        case 'flam':
            return {
                backgroundColor: getThemeColor('brand-flam', '100'),
                color: getThemeColor('brand-flam', '800'),
                icon: 'flam',
                label: 'device_target_tag_flam'
            };
        case 'fah': case 'fah_flam': default:
            return {
                backgroundColor: getThemeColor('brand-secondary', '100'),
                color: getThemeColor('brand-secondary', '800'),
                icon: 'storyteller',
                label: 'device_target_tag_fah'
            };
    }
}

export function ProductTaxonomy({className = '', product}: ProductTaxonomyProps) {
    const mode: 'fah_flam' | 'fah' | 'flam' | undefined = getTargetDevice(product.deviceTarget);
    const {label, icon, color, backgroundColor} = getProperties(mode);
    const classes = useStyles({color, backgroundColor});
    const {t} = useLuniiTranslation();
    const handleEventTracking = useLuniiEventTracking();
    const locale = (product.localesAvailable || [])[0].name || '';
    const infos = [
        {
            title: t('product_age_title'),
            text: product.ageMin,
        },
        {
            title: t('product_duration_title'),
            text: product.duration,
        },
        {
            title: t('product_language_title'),
            text: t(`locale_${locale}_product_language`),
            choices: product.languagesAvailable,
        },
    ]
    const handleChange = (newLocale: string) => {
        handleEventTracking({
            categorie: 'interaction',
            action: 'selecteur',
            libelle: t(`locale_${newLocale}_product_language`)
        });
        const {url} = (product.languagesAvailable || []).find((l: any) => newLocale === l.locale) || {};
        if (url) navigate(url);
    };
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.deviceTarget}>
                <DynamicIcon type={`${icon}-24`} className={classes.icon}/>
                <div>{t(label)}</div>
            </div>
            <div className={classes.content}>
                <div className={classes.infos}>
                    {infos.map(({title, text, choices}) => (
                        <div key={Math.random()}>
                            <div className={classes.title}>{title}</div>
                            {(!choices || !product.languagesAvailable?.length) && <div className={classes.text}>{text}</div> || false}
                            {choices && product.languagesAvailable?.length && (
                                <DropDownLanguage
                                    locale={locale}
                                    localesAvailable={product.languagesAvailable.map(elt => elt.locale) as string[]}
                                    onChange={handleChange}/>
                            ) || false}
                        </div>
                    ))}
                </div>
                {product.types && (
                    <div className={classes.types}>
                        <div>
                            <div className={classes.title}>{t('product_type_title')}</div>
                            <div className={classes.text}>{product.types.name}</div>
                        </div>
                        {product.types.image && <Img className={classes.image} {...product.types.image} />}
                    </div>
                )}
            </div>

        </div>
    );
}

export interface ProductTaxonomyProps {
    className?: string;
    product: digital_album_product;
}

export default ProductTaxonomy;
