import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import {HistoryLocation, useLocation} from '@reach/router';
import {CheckoutLayout} from '../../layouts';
import {
    AcceptedCartItemRemovalReason,
    buttonifyFromProps,
    CartItemRemovalReason,
    ForceTheme,
    LargeTitleText,
    MergeAccountWarningModal,
    ProductTableNameEnum,
    removed_items,
    Row,
    ShadowedPanel,
    useLuniiNavigation,
    useLuniiSpa,
    useLuniiTranslation,
    useLuniiUser,
    useShoppingCart,
} from '../../../ui';
import {Alert, CheckoutCartInfos, ShoppingCartCheckout} from '../../molecules';
import {useCheckoutTracking} from '../../../hooks';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    removedItems: {
        marginBottom: theme.spacing(3),
    },
    left: {
        position: 'relative',
        '& >*': {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: 128,
        },
    },
    right: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& >*': {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        },
    },
    panel: {
        width: '100%',
        backgroundColor: 'white',
        padding: theme.spacing(2),
    },
    btn: {
        width: '100%',
    },
    removedItem: {
        fontStyle: 'italic',
        fontWeight: 800,
    },
}));

export function CartScreen() {
    useCheckoutTracking(true, 'panier');
    const classes = useStyles();
    const [{cart}] = useShoppingCart();
    const location: HistoryLocation = useLocation();
    const {user} = useLuniiUser();
    const {t} = useLuniiTranslation();
    const {navigate} = useLuniiSpa();
    const [openModal, setOpenModal] = useState<{open: boolean, mode?: 'fah' | 'flam' | 'fah_flam'}>({open: false});
    const {goHome} = useLuniiNavigation();
    const {Button} = buttonifyFromProps(
        {
            buttonLabel: t('checkout_cart_return'),
            buttonTarget: goHome,
        },
        ['primary,size=large'],
    );
    const closeModal = () => {
        openModal.mode && setOpenModal({open: false, mode: undefined});
    };
    const goToPaymentPage = () => {
        closeModal();
        navigate('/payment');
    };
    const goToLoginPage = () => {
        closeModal();
        navigate('/login');
    };
    const handleNextStep = () => {
        if (!user) {
            goToLoginPage();
            return;
        } // if !user => do nothing
        const fahPacksInCart = cart?.items?.some((item) => item.type === ProductTableNameEnum.Packs && item.deviceTarget && item.deviceTarget.indexOf('fah') !== -1);
        const flamPacksInCart = cart?.items?.some((item) => item.type === ProductTableNameEnum.Packs && item.deviceTarget && item.deviceTarget.indexOf('flam') !== -1);
        const deviceTarget: string[] = [];
        if (!user.hasFahs && fahPacksInCart) deviceTarget.push('fah'); // usecase => user has no fah but has fah audiobooks in his cart
        if (!user.hasFlam && flamPacksInCart) deviceTarget.push('flam'); // usecase => user has no flam but has flam audiobooks in his cart
        if (deviceTarget.length > 0) {
            !openModal.open && setOpenModal({open: true, mode: deviceTarget.join('_') as 'fah' | 'flam' | 'fah_flam'});
        } else {
            goToPaymentPage();  // default => go to payment page
        }
    };
    return (
        <CheckoutLayout>
            <Row
                padding="default-top"
                alignItems="flex-start"
                justify="space-between"
                gridClassName={classes.container}
            >
                <Grid
                    item
                    container
                    lg={4}
                    md={5}
                    sm={12}
                    className={classes.left}
                    direction="column"
                >
                    <HiddenCss smDown>
                        <LargeTitleText className={classes.title as any}>
                            {t('checkout_cart_title')}
                        </LargeTitleText>
                    </HiddenCss>
                    {(location?.state?.removedItems || [])
                        .filter((removedItem: removed_items) => AcceptedCartItemRemovalReason.indexOf(removedItem.code) !== -1 && removedItem.code !== CartItemRemovalReason.mixed_array)
                        .map((removedItem: removed_items) => (
                            <Alert
                                className={classes.removedItems}
                                theme='error'
                                description={(
                                    <div>
                                        <div>{t(`checkout_cart_removed_items_${(removedItem.code || '')}`)}</div>
                                        {removedItem.items?.filter(item => item.id).map((item) => (
                                            <div className={classes.removedItem}>{`"${item.name || item.id}"`}</div>
                                        ))}
                                    </div>
                                )}
                            />
                        ))}
                    {((cart || {}).items || []).length > 0 ? (
                        <CheckoutCartInfos
                            cart={cart}
                            onNextStep={handleNextStep}
                        />
                    ) : (
                        Button && <Button className={classes.btn} />
                    )}
                </Grid>
                <Grid
                    item
                    container
                    lg={7}
                    md={6}
                    sm={12}
                    className={classes.right}
                    direction="column"
                >
                    <HiddenCss mdUp>
                        <LargeTitleText className={classes.title as any}>
                            {t('checkout_cart_title')}
                        </LargeTitleText>
                    </HiddenCss>
                    <ShadowedPanel className={classes.panel}>
                        <ForceTheme theme="default">
                            <ShoppingCartCheckout cart={cart} />
                        </ForceTheme>
                    </ShadowedPanel>
                </Grid>
            </Row>
            <MergeAccountWarningModal
                isOpen={openModal.open}
                mode={openModal.mode}
                onClose={closeModal}
                onConfirm={goToPaymentPage}
                onLogout={goToLoginPage}
            />
        </CheckoutLayout>
    );
}

export default CartScreen;
